import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    remove,
    changeStatus,
} from 'Redux/modules/admin/articleComments/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            remove: dispatch(remove),
            changeStatus: dispatch(changeStatus),
        },
    }),
)(Component);