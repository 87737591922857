import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_BLOG } from 'Consts/routes';
import { 
    ARTICLE_COMMENT_STATUSES,
    STATUS_APPROVED,
    STATUS_NOT_APPROVED,
} from 'Consts/articleComments';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminArticleCommentEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => {
        return {
            ...data,
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            status: fromSelectObject(formState.status),
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            id: data.id,
            ...this.formStateToData(formState),
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(
                withVariables(
                    ADMIN_BLOG.path,
                    {}, 
                    {}
                )
            );
        });
    }

    render() {
        const { location, history, actions, data } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-article-comment-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'author',
                            label: 'Autor',
                            required: true,
                        }, {
                            required: true,
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz',
                        }, {
                            required: true,
                            type: 'select',
                            name: 'status',
                            label: 'Status opublikowania',
                            options: ARTICLE_COMMENT_STATUSES.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }],
                    }]}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń',
                        subtitle: 'Komentarz zostanie usunięty',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                history.push(
                                    withVariables(
                                        ADMIN_BLOG.path,
                                        {}, 
                                        {}
                                    )
                                );
                            }),
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }]}
                />
            </StyledComponent>
        );
    }
}